<div class="photo-dashboard">
  <h2>Photo Gallery</h2>

  <!-- Toggle admin mode button -->
  <button (click)="toggleAdminMode()">
    {{ isAdmin ? 'Exit Admin Mode' : 'Enter Admin Mode' }}
  </button>

  <!-- If admin mode is off and the user clicked to enter admin mode, show the password input -->
  <div *ngIf="!isAdmin && showPasswordPrompt">
    <input
      type="password"
      placeholder="Enter admin password"
      [(ngModel)]="adminPassword"
    />
    <button (click)="verifyAdminPassword()">Submit</button>
    <p *ngIf="invalidPassword" style="color: red;">Incorrect password. Try again.</p>
  </div>

  <!-- Show file input and upload button only in admin mode -->
  <div *ngIf="isAdmin">
    <input type="file" (change)="onFileSelected($event)" />
    <button (click)="uploadPhoto()">Upload Photo</button>
  </div>

  <h3>Uploaded Photos</h3>

  <!-- Photo Grid -->
  <div class="photo-grid">
    <div class="photo-item" *ngFor="let photo of photos">
      <img [src]="photo.url" [alt]="photo.filename" />
      <p>{{ photo.filename }}</p>

      <!-- Show download button for each photo -->
      <button (click)="downloadImage(photo.id)">Download</button>

      <!-- Show delete button only in admin mode -->
      <button *ngIf="isAdmin" (click)="deletePhoto(photo.id)">Delete</button>
    </div>
  </div>
</div>
