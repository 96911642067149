import { Component, OnInit } from '@angular/core';
import { PhotoService } from '../photo.service';
import { Photo } from '../photo'; 

@Component({
  selector: 'app-photo-dashboard',
  templateUrl: './photo-dashboard.component.html',
  styleUrls: ['./photo-dashboard.component.css']
})
export class PhotoDashboardComponent implements OnInit {
  photos: Photo[] = [];  // array of Photo objects
  selectedFile: File | null = null;

  // Admin-related variables
  isAdmin: boolean = false;        // Flag to check if admin mode is active
  adminPassword: string = '';      // Bound to the input field
  showPasswordPrompt: boolean = false; // Determines whether to show the password prompt
  invalidPassword: boolean = false; // To show error message on invalid password

  // Hardcoded admin password for simplicity (in real scenario, replace with more secure mechanism)
  correctPassword: string = 'admin123'; 

  constructor(private photoService: PhotoService) {}

  ngOnInit(): void {
    this.fetchPhotos();
  }

  // Toggle admin mode and show password prompt if not already admin
  toggleAdminMode(): void {
    if (this.isAdmin) {
      this.isAdmin = false; // Exit admin mode
      this.showPasswordPrompt = true; // Show password prompt again
      this.adminPassword = ''; // Clear password input
    } else {
      this.showPasswordPrompt = true; // Show password prompt
    }
  }

  // Verify password when trying to enter admin mode
  verifyAdminPassword(): void {
    console.log('Entered password:', this.adminPassword);  // Debugging

    if (this.adminPassword === this.correctPassword) {
      this.isAdmin = true;
      this.showPasswordPrompt = false;
      this.invalidPassword = false;
      this.adminPassword = ''; // Clear the input after checking
    } else {
      this.invalidPassword = true; // Incorrect password logic
    }
  }

  fetchPhotos(): void {
    this.photoService.getPhotos().subscribe((data: Photo[]) => {
      console.log('Fetched photos:', data); // Debugging: log structured data
      this.photos = data.map(photo => ({
        ...photo, // Spread operator to retain other properties like 'id' and 'filename'
        url: `http://localhost:8080/api/photos/download/${photo.id}`  // Use 'id' to construct the URL
      }));
    });
  }

  onFileSelected(event: any): void {
    this.selectedFile = event.target.files[0];
  }

  uploadPhoto(): void {
    if (!this.isAdmin) {
      console.log('Upload is only available in admin mode.');
      return; // Do nothing if not in admin mode
    }

    if (this.selectedFile) {
      this.photoService.uploadPhoto(this.selectedFile).subscribe(() => {
        this.fetchPhotos(); // Refresh the photo list
        this.selectedFile = null; // Clear the selected file
      });
    }
  }

  downloadImage(photoId: number): void {
    this.photoService.downloadPhoto(photoId).subscribe(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'image.png';  // Set a default name for the downloaded image
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    });
}

  deletePhoto(photoId: number): void {
    if (!this.isAdmin) {
      console.log('Delete is only available in admin mode.');
      return; // Do nothing if not in admin mode
    }

    console.log("Deleting photo with id: ", photoId);  // Check if 'photoId' is valid before making the request
    this.photoService.deletePhoto(photoId).subscribe(() => {
      this.fetchPhotos(); // Refresh the list after deletion
    });
  }

}
